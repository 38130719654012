const animationConfig = {
	up: {
		from: {
			y: 50,
		},
		to: {
			y: 0,
		},
	},
	right: {
		from: {
			x: -50,
		},
		to: {
			x: 0,
		},
	},
	down: {
		from: {
			y: -50,
		},
		to: {
			y: 0,
		},
	},
	left: {
		from: {
			x: 50,
		},
		to: {
			x: 0,
		},
	},
	'up-right': {
		from: {
			x: -50,
			y: 50,
		},
		to: {
			x: 0,
			y: 0,
		},
	},
	'down-right': {
		from: {
			x: -50,
			y: -50,
		},
		to: {
			x: 0,
			y: 0,
		},
	},
	'up-left': {
		from: {
			x: 50,
			y: 50,
		},
		to: {
			x: 0,
			y: 0,
		},
	},
	'down-left': {
		from: {
			x: 50,
			y: -50,
		},
		to: {
			x: 0,
			y: 0,
		},
	},
	static: {
		from: {},
		to: {},
	},
};

function initScrollAnimations() {
	gsap.utils.toArray( '[fad-animation-type]' ).forEach( ( el ) => {
		const fromConfig = Object.assign(
			{},
			animationConfig[ el.getAttribute( 'fad-animation-direction' ) ].from
		);

		const toConfig = Object.assign(
			{},
			animationConfig[ el.getAttribute( 'fad-animation-direction' ) ].to
		);

		fromConfig.autoAlpha = 0;
		toConfig.autoAlpha = 1;

		if ( 'zoom-in' === el.getAttribute( 'fad-animation-type' ) ) {
			fromConfig.scale = 0.6;
			toConfig.scale = 1;
		}

		if ( 'zoom-out' === el.getAttribute( 'fad-animation-type' ) ) {
			fromConfig.scale = 1.2;
			toConfig.scale = 1;
		}

		// Set duration
		toConfig.duration = el.getAttribute( 'fad-animation-duration' );

		// Set Scroll Trigger
		toConfig.scrollTrigger = {
			trigger: el,
			start: 'top 75%',
			toggleActions: 'restart none none reverse',
		};

		// Initialize
		gsap.fromTo( el, fromConfig, toConfig );
	} );
}

document.addEventListener( 'DOMContentLoaded', () => {
	initScrollAnimations();
} );
